import React, { useCallback, useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userPool from 'src/shared/utils/userPool';
import noop from 'lodash/noop';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Input from 'src/shared/components/input/Input';
import { PrimaryButton } from 'src/shared/components/button/Button';

import PasswordInput from 'src/shared/components/password-input/PasswordInput';
import { isEmailIdValid } from 'src/shared/helpers/emailHelpers';
import styles from './Signin.module.scss';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const ForgotPassword = () => {
    const navigate = useAppNavigation();
    const [state, setState] = useState({
        step: 1,
        email: '',
        verificationCode: '',
        password: '',
        confirmPassword: '',
        errorMessage: '',
        successMessage: '',
    });
    const { step, email, verificationCode, password, confirmPassword, errorMessage, successMessage } = state;

    // Create the CognitoUser instance once and reuse it
    const [user, setUser] = useState<CognitoUser | null>(null);

    useEffect(() => {
        if (isEmailIdValid(email)) {
            setUser(
                new CognitoUser({
                    Username: email.toLowerCase(),
                    Pool: userPool,
                })
            );
        }
    }, [email]);

    const updateState = (fragment: Partial<typeof state>) => {
        setState({ ...state, ...fragment });
    };

    const sendVerificationCode = useCallback(
        (e: React.BaseSyntheticEvent) => {
            e.preventDefault();
            updateState({ successMessage: '', errorMessage: '' });

            if (!isEmailIdValid(email)) {
                updateState({ errorMessage: 'Please enter a valid email address.' });
                return;
            }
            user?.forgotPassword({
                onSuccess: noop,
                onFailure: error => {
                    updateState({ errorMessage: error.message });
                },
                inputVerificationCode: () => {
                    updateState({ successMessage: 'A verification code has been sent to the registered phone number.', step: 2 });
                },
            });
        },
        [user]
    );

    const handleResetPassword = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        updateState({ successMessage: '', errorMessage: '' });

        if (password !== confirmPassword) {
            updateState({ errorMessage: 'Passwords do not match.' });
            return;
        }

        user?.confirmPassword(verificationCode, password, {
            onSuccess: () => {
                updateState({ successMessage: 'Your password changed successfully, please login with new password.' });
            },
            onFailure: error => {
                updateState({ errorMessage: error.message });
            },
        });
    };

    const resendVerificationCode = () => {
        updateState({ successMessage: '', errorMessage: '' });
        user?.forgotPassword({
            onSuccess: noop,
            onFailure: error => {
                console.error('error', error);
                updateState({ errorMessage: error.message });
            },
            inputVerificationCode: () => {
                updateState({ successMessage: 'A verification code has been re-sent to the registered phone number.', step: 2 });
            },
        });
    };

    const stepOne = (
        <form onSubmit={sendVerificationCode} noValidate className={styles.forgotPasswordForm}>
            <Input
                placeholder='Email*'
                onChange={e => updateState({ email: e.target.value })}
                value={email.toLocaleLowerCase()}
                autoFocus
                required
            />

            <PrimaryButton type='submit'>Send Verification Code</PrimaryButton>
        </form>
    );

    const stepTwo = (
        <form onSubmit={handleResetPassword} noValidate className={styles.forgotPasswordForm}>
            <Input placeholder='Email' value={email.toLocaleLowerCase()} disabled />

            <Input
                placeholder='Verification Code*'
                onChange={e => updateState({ verificationCode: e.target.value })}
                value={verificationCode}
                required
            />

            <span onClick={resendVerificationCode} className={styles.resendLink}>
                Resend code
            </span>

            <PasswordInput placeholder='Password*' value={password} onChange={e => updateState({ password: e.target.value })} required />

            <PasswordInput
                placeholder='Confirm Password*'
                value={confirmPassword}
                onChange={e => updateState({ confirmPassword: e.target.value })}
                required
            />

            <PrimaryButton onSubmit={handleResetPassword}>Submit</PrimaryButton>
        </form>
    );

    return (
        <div className={styles.forgotPasswordContainer}>
            <div className={styles.lockIconContainer}>
                <LockOutlinedIcon />
            </div>
            <div className={styles.forgotPasswordTitle}>Forgot Password</div>

            {step === 1 && stepOne}

            {step === 2 && stepTwo}

            {errorMessage && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity='error'>{errorMessage}</Alert>
                </Stack>
            )}
            {successMessage && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity='success'>{successMessage}</Alert>
                </Stack>
            )}

            <div className={styles.loginLink} onClick={navigate.toHome}>
                Login
            </div>
        </div>
    );
};
export default ForgotPassword;
